import { useItineraryStore } from '~/stores/api/ItineraryStore';
import { CanNotInsertTransportRequestError } from '~/exceptions/itinerary/day/CanNotInsertTransportRequest';
import { ChooseNewConfigurationError } from '~/exceptions/itinerary/day/ChooseNewConfiguration';
import type ItineraryPayload from '~/api/payload/ItineraryPayload';
import { CanInsertTransportRequestEnum } from '~/api/enum/CanInsertTransportRequestEnum';
import { useGlobalLoadingStore } from '~/stores/common/GlobalLoadingStore';

export const useItineraryDayStore = defineStore('itinerary-day', {
  state: (): {
    currentGroupedTransportRequestId: string | null;
    currentMarkerId: number | null;
    currentItineraryId: number | null;
    softRefresh: boolean;
  } => ({
    currentMarkerId: null,
    currentGroupedTransportRequestId: null,
    currentItineraryId: null,
    softRefresh: false
  }),
  actions: {
    setCurrentMarker(idMarker: number | null) {
      this.currentMarkerId = idMarker;
    },
    setCurrentGroupedTransportRequestId(currentGroupedTransportRequestId: string | null) {
      this.currentGroupedTransportRequestId = currentGroupedTransportRequestId;
    },
    setCurrentItineraryId(itineraryId: number | null) {
      this.currentItineraryId = itineraryId;
      this.setCurrentMarker(null);
    },

    async deleteItinerary(itineraryId: number) {
      const { setLoading } = useGlobalLoadingStore();
      setLoading(true);

      const itineraryStore = useItineraryStore();
      await itineraryStore.deleteItinerary(itineraryId);
      this.currentItineraryId = null;
      await refreshNuxtData('itinerary-day-full');
    },
    async insertPoint(idCurrentItinerary: number, idTransportRequest: number, checkIfCanInsert: boolean, idConfiguration?: number) {
      this.softRefresh = true;
      const itineraryStore = useItineraryStore();

      if (checkIfCanInsert) {
        const result = await itineraryStore.canInsertTransportRequest(idCurrentItinerary, idTransportRequest);

        if (result.status == CanInsertTransportRequestEnum.KO) {
          this.softRefresh = false;
          throw CanNotInsertTransportRequestError();
        }

        if (result.status == CanInsertTransportRequestEnum.CHOOSE_ANOTHER_CONFIGURATION) {
          this.softRefresh = false;
          throw ChooseNewConfigurationError(result);
        }
      }

      await itineraryStore.insertTransportRequest(idCurrentItinerary, idTransportRequest, idConfiguration);
      await refreshNuxtData('itinerary-day-soft');
    },

    reset() {
      this.$reset();
    },
    async createItinerary(itineraryPayload: ItineraryPayload) {
      const itineraryStore = useItineraryStore();

      this.softRefresh = true;
      await itineraryStore.createItinerary(itineraryPayload);
      await refreshNuxtData('itinerary-day-soft');
    }
  }
});
